import { ArrowBack, ArrowForward, CopyAll, Download } from '@mui/icons-material'
import { AppBar, Box, Chip, Collapse, Fade, IconButton, Stack, Tab, Tabs, Toolbar, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { File } from "../../File/File"
import { enqueueSnackbar } from 'notistack'

export const FileComponent = ({ files = [], fileComponent, setFileComponent }) => {
    const [fileIndex, setFileIndex] = useState(0)
    const selectedFile = files[fileIndex]
    const fileName = selectedFile['name']
    return (
        <Stack height={'100%'} width={'100%'}>
            <AppBar variant='outlined' position='static'>
                <Toolbar variant='dense' sx={{ gap: 1, pr: 1 }} disableGutters >
                    <IconButton color={'inherit'} onClick={() => setFileComponent(prev => !prev)}>
                        {fileComponent ?
                            <ArrowBack />
                            :
                            <ArrowForward />
                        }
                    </IconButton>

                    {files.length > 1 &&
                        <Tabs
                            variant="scrollable"
                            value={fileIndex}
                            sx={{
                                height: '100%',
                                flexGrow: 5,
                                alignItems: 'center',
                                '& .MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 },
                                '& .MuiTabs-scrollButtons': { height: '100%' },
                            }}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                        >

                            {files.map((_, _fileIndex) =>
                                <Tab
                                    disableRipple
                                    key={_fileIndex}
                                    sx={{
                                        minWidth: '32px',
                                        padding: '12px 6px'
                                    }}
                                    icon={
                                        <Chip
                                            color={
                                                fileIndex === _fileIndex ? "warning" : "default"
                                            }
                                            variant={
                                                fileIndex === _fileIndex ? "contained" : "outlined"
                                            }
                                            label={_fileIndex + 1}
                                            onClick={() => setFileIndex(_fileIndex)}
                                            sx={{ color: 'white' }}
                                        />
                                    }
                                />
                            )}
                        </Tabs>
                    }
                    <Tooltip arrow title={fileName}>
                        <Chip
                            variant='outlined'
                            label={fileName}
                            sx={{ color: 'white', maxWidth: '55%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            onDelete={() => {
                                navigator.clipboard.writeText(fileName);
                                enqueueSnackbar('Copied')
                            }}
                            deleteIcon={<CopyAll />}
                            icon={
                                <IconButton size='small' color='warning '>
                                    <Download fontSize='small' />
                                </IconButton>
                            }
                        />
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Fade in={fileComponent} unmountOnExit style={{ transitionDelay: '0.7s' }}>
                <Box flexGrow={1} overflow={'auto'}>
                    <File s3_key={selectedFile?.['s3_key']} name={fileName} height='99%' />
                </Box>
            </Fade>
        </Stack>
    )
}
