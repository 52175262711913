import React, { useEffect, useRef, useState } from "react";

import {
    Box,
    Stack,
    Typography,
    Tooltip,
    TextField,
    Autocomplete,
} from "@mui/material";

import AutoSizer from "react-virtualized-auto-sizer";

import { Table } from 'antd';

import {
    DownOutlined,
    UpOutlined
} from '@ant-design/icons';

import "./ReviewTable.css"
import ExpandPanel from "./ExpandPanel";

export default function ReviewTable({ data = {}, updateData = () => { }, tab = 0, prevFState = true, RenderFooterButtons = <></> }) {

    const tabWise = {
        0: "revenue",
        1: "receipt",
        2: "arDetails",
        3: "statistics",
        4: 'verification'
    }

    const NUM_COL_WIDTH = 150

    const finalTableData = processData(data[tabWise[tab]], tab)

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    function getAllLineItem() {
        return finalTableData?.reduce((acc, current, index) => {
            const detailedWithIndex = current.lineItemsGroup_Detailed?.map(item => ({ lineItem: item['lineItem'], index, uniqueId: current['uniqueId'] }));
            const summaryWithIndex = current.lineItemsGroup_summary?.map(item => ({ lineItem: item['lineItem'], index, uniqueId: current['uniqueId'] }));
            return acc.concat(detailedWithIndex, summaryWithIndex);
        }, []);
    }

    const tableRef = useRef()
    const [scrollToRow, setScrollToRow] = useState(null)
    useEffect(() => {
        setScrollToRow(null)
    }, [tab])

    useEffect(() => {
        let current = tableRef.current
        if (scrollToRow) {
            current.scrollTo({
                key: scrollToRow['uniqueId']
            });
        }
    }, [scrollToRow, tableRef.current])

    function addExpandedRowKey(key) {
        setExpandedRowKeys([...expandedRowKeys, key])
    }

    function removeExpandedRowKey(key) {
        setExpandedRowKeys(expandedRowKeys.filter(i => i !== key))
    }

    // const fileComponent = prevFState

    const [fileComponent, setFileComponent] = useState(prevFState)

    useEffect(() => {
        if (!prevFState) {
            const timer = setTimeout(() => {
                setFileComponent(prevFState);
            }, 800);
            return () => clearTimeout(timer);
        }
        else {
            setFileComponent(prevFState);
        }
    }, [prevFState]);

    return tab !== 4 ?
        <AutoSizer>
            {({ width, height }) => {
                return (
                    <Box height={height} width={width}>
                        <Table
                            size='middle'
                            scroll={{ y: (height - 8) - (48 * 3) }}
                            virtual
                            bordered
                            pagination={false}
                            dataSource={finalTableData}
                            ref={tableRef}
                            columns={[
                                {
                                    dataIndex: "Label",
                                    key: "Label",
                                    title: "Label",
                                    ellipsis: {
                                        showTitle: false,
                                    },
                                    render: (Label, record) => (
                                        record['isDepartment'] ?
                                            <Typography variant="h6">{record['Department']}</Typography> :
                                            <Tooltip arrow title={Label}>
                                                <span>
                                                    {(scrollToRow && (scrollToRow['uniqueId'] === record['uniqueId'])) ?
                                                        <mark dangerouslySetInnerHTML={{ __html: Label }} />
                                                        :
                                                        Label
                                                    }
                                                </span>
                                            </Tooltip >
                                    ),
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? (fileComponent ? 3 : 6) : 1,
                                        }
                                    ),
                                },
                                {
                                    dataIndex: "lineName",
                                    key: "lineName",
                                    title: "Nimble Label",
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? 0 : 1
                                        }
                                    ),
                                    ellipsis: {
                                        showTitle: false,
                                    },
                                    render: (Label, record) => (
                                        <Tooltip arrow title={Label}>
                                            {Label}
                                        </Tooltip>
                                    ),
                                    hidden: fileComponent
                                },
                                {
                                    dataIndex: "netAmount",
                                    key: "netAmount",
                                    title: "Detail Amount",
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? 0 : 1,
                                        }
                                    ),
                                    width: NUM_COL_WIDTH,
                                },
                                {
                                    dataIndex: "netStat",
                                    key: "netStat",
                                    title: "Detailed Stat",
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? 0 : 1,
                                        }
                                    ),
                                    width: NUM_COL_WIDTH,
                                },
                                {
                                    dataIndex: "netAmount_summary",
                                    key: "netAmount_summary",
                                    title: "Summary Amount",
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? 0 : 1,
                                        }
                                    ),
                                    width: NUM_COL_WIDTH,
                                    hidden: fileComponent
                                },
                                {
                                    dataIndex: "netStat_summary",
                                    key: "netStat_summary",
                                    title: "Summary Stat",
                                    onCell: (data, index) => (
                                        {
                                            colSpan: data['isDepartment'] ? 0 : 1,
                                        }
                                    ),
                                    width: NUM_COL_WIDTH,
                                    hidden: fileComponent
                                },
                            ]}
                            rowKey="uniqueId"

                            expandable={{
                                columnWidth: 64,
                                // columnTitle: <IconButton size="small"><Add /></IconButton>,
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    let detailedLineItems = record.lineItemsGroup_Detailed || []
                                    let summaryLineItems = record.lineItemsGroup_summary || []

                                    let hasLineItems = detailedLineItems.length > 0 || summaryLineItems.length > 0

                                    return (
                                        !record['isDepartment'] ?
                                            expanded ? (
                                                <UpOutlined onClick={e => removeExpandedRowKey(record['uniqueId'])} />
                                            ) : (
                                                <DownOutlined onClick={e => addExpandedRowKey(record['uniqueId'])} />
                                            )
                                            :
                                            <></>
                                    )
                                },
                                expandedRowRender: (record) => <ExpandPanel fileComponent={fileComponent} data={data} updateData={updateData} label={record} />,
                                expandedRowKeys: expandedRowKeys
                            }}
                            footer={() => RenderFooterButtons(data)}
                            title={() =>
                                <Stack direction={'row'} height={'48px'} gap={2} justifyContent={'flex-end'} alignItems={'center'}>

                                    <Autocomplete
                                        size='small'
                                        value={scrollToRow || null}
                                        sx={{ width: '200px' }}
                                        options={finalTableData || []}
                                        getOptionLabel={(option) => option['Label'] || ""}
                                        renderInput={(params) => <TextField {...params} placeholder="Search Label" variant="standard" />}
                                        onChange={(e, v) => { if (v) { setScrollToRow({ ...v, expand: false }); return } setScrollToRow(v) }}
                                    />
                                    <Autocomplete
                                        size='small'
                                        value={scrollToRow || null}
                                        sx={{ width: '200px' }}
                                        options={getAllLineItem()}
                                        getOptionLabel={(option) => option['lineItem'] || ""}
                                        renderInput={(params) => <TextField {...params} placeholder="Search LineItem" variant="standard" />}
                                        onChange={(e, v) => { if (v) { setScrollToRow({ ...v, expand: true }); return } setScrollToRow(v) }}
                                    />
                                </Stack>
                            }
                        />
                    </Box>
                )
            }}
        </AutoSizer >
        :
        <Box
            height="100%"
            overflow={'auto'}
        >
            <Table
                bordered
                pagination={false}
                size="middle"
                dataSource={finalTableData}
                columns={[
                    {
                        dataIndex: "lineitem_desc",
                        title: "Label",
                        hidden: tab !== 4
                    },
                    {
                        dataIndex: "net_Amount",
                        title: "Amount",
                        hidden: tab !== 4
                    },
                    {
                        dataIndex: "stat",
                        title: "Stat",
                        hidden: tab !== 4
                    },
                ]}
            />
        </Box>
}

function processData(tempTables, tab) {
    const uuid = require('uuid');
    let finalData = []
    if (tempTables) {
        if (tab !== 4) {
            let NimbleDeptOrder;
            switch (tab) {
                case 0:
                    NimbleDeptOrder = ["Room Revenue", "F & B Revenue", "Gift shop Revenue", "Miscellaneous Revenue", "Non-Revenue", "Taxes Collected"];
                    break
                case 1:
                    NimbleDeptOrder = ["Cash Receipts", "Credit Card Receipts", "Cash Over/Short", "Other Receipts"];
                    break
                case 2:
                    NimbleDeptOrder = ["AR_Ledger"];
                    break
                case 4:
                    NimbleDeptOrder = ['Statistics'];
                    return tempTables
                default:
                    NimbleDeptOrder = [];
            }
            tempTables.sort((a, b) => {
                const indexA = NimbleDeptOrder.indexOf(a.Department);
                const indexB = NimbleDeptOrder.indexOf(b.Department);
                return indexA - indexB;
            });
            // console.log(tempTables)
            // tempTables.sort((a, b) => a.departmentType - b.departmentType);

            tempTables.forEach(i => i['isDepartment'] = false)

            let departmentWise = tempTables.reduce((acc, obj) => {
                const key = obj.Department;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            Object.entries(departmentWise).map(([key, value], index) => {
                finalData.push({
                    "uniqueId": uuid.v4(),
                    "Department": key, //Its Department
                    "isDepartment": true,
                    "lineItemsGroup_summary": [],
                    "lineItemsGroup_Detailed": []
                    // "netAmount": value.reduce((accumulator, currentObject) => accumulator + currentObject.netAmount, 0),
                    // "netStat": value.reduce((accumulator, currentObject) => accumulator + currentObject.netStat, 0),
                    // "netAmount_summary": value.reduce((accumulator, currentObject) => accumulator + currentObject.netAmount_summary, 0),
                    // "netStat_summary": value.reduce((accumulator, currentObject) => accumulator + currentObject.netStat_summary, 0),
                })
                finalData.push(...value)
            })
        }
        else {
            finalData = tempTables
        }
    }

    return finalData
}

// return expandRows.includes(rowData['uniqueId']) ?
//     <Stack style={{ ...style, height: 400 }} {...rest}>
//         {defaultTableRowRenderer({
//             ...props,
//             style: { width: style.width }
//         })}
//         <Stack height={400} border={1} direction={'row'}>

//         </Stack>
//     </Stack>
//     :
//     defaultTableRowRenderer(props)