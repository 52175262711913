// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    font-family: Calibri;
    font-weight: 100;
    border-spacing: 0px;
    border-collapse: collapse;
}

.table th {
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    height: 17px;
}

.table td {
    border: 0px;
    background-color: white;
    padding: 0px 4px 0px 2px;
    border: 1px solid #d0d7e5;
    border-width: 0px 1px 1px 0px;
    text-wrap: nowrap;
}

.table td b {
    border: 0px;
    background-color: white;
    font-weight: bold;
}

.table td.heading {
    background-color: #e4ecf7;
    text-align: center;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    font-size: 14px;
    font-weight: bold;
}

.table th.heading {
    background-repeat: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/File/File.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,wBAAwB;IACxB,yBAAyB;IACzB,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".table {\n    border: 1px solid #b0cbef;\n    border-width: 1px 0px 0px 1px;\n    font-size: 11pt;\n    font-family: Calibri;\n    font-weight: 100;\n    border-spacing: 0px;\n    border-collapse: collapse;\n}\n\n.table th {\n    font-weight: bold;\n    font-size: 14px;\n    border: 1px solid #9eb6ce;\n    border-width: 0px 1px 1px 0px;\n    height: 17px;\n}\n\n.table td {\n    border: 0px;\n    background-color: white;\n    padding: 0px 4px 0px 2px;\n    border: 1px solid #d0d7e5;\n    border-width: 0px 1px 1px 0px;\n    text-wrap: nowrap;\n}\n\n.table td b {\n    border: 0px;\n    background-color: white;\n    font-weight: bold;\n}\n\n.table td.heading {\n    background-color: #e4ecf7;\n    text-align: center;\n    border: 1px solid #9eb6ce;\n    border-width: 0px 1px 1px 0px;\n    font-size: 14px;\n    font-weight: bold;\n}\n\n.table th.heading {\n    background-repeat: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
