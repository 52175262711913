import React, { useEffect, useState } from 'react'
import { useLoaderData, useOutletContext } from 'react-router-dom'
import { getClientList, getPropertiesList } from '../../API/API'
import { ClientsList } from '../../Components/DropDowns'
import { AppBar, Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, LinearProgress, Stack, Toolbar, Typography } from '@mui/material'
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import { ArrowBack, ArrowForward, Clear } from '@mui/icons-material'
import { Table } from 'rsuite';
import axios from "axios"
import SubmitPage from '../../Components/SubmitPage/SubmitPage'
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import DialogTransition from '../../Components/DialogTransition'
import zIndex from '@mui/material/styles/zIndex'
import VirtualizedTable from '../../Components/ReactVirtualizedTable/VirtualizedTable '
import Loading from '../../Components/Loading'
import moment from 'moment'

const { Column, HeaderCell, Cell } = Table;
const { startOfWeek, endOfWeek, format, eachDayOfInterval } = require('date-fns');

const Inbox = () => {

    const [clientList, setClientList] = useState(null)
    const [propertiesList, setPropertiesList] = useState([])
    const [sourceFile, setSourceFile] = useState();
    const [dataFrames, setDataFrames] = useState([])

    const [client, setClient] = useState(null)

    const [selectedEmail, setSelectedEmail] = useState()
    const [backDropState, setBackdropState] = useState(false)

    const [RemovedLineItemTrack, setRemovedLineItemTrack] = useState([])
    const [RemovedDataFrames, setRemovedDataFrames] = useState([])

    const presentDate = new Date()
    const [weekRange, setWeekRange] = useState([startOfWeek(presentDate), endOfWeek(presentDate)])

    const datesInRange = eachDayOfInterval({ start: weekRange[0], end: weekRange[1] });
    const [tableData, setTableData] = useState([])

    const [processingData, setProcessingData] = useState(false)

    useEffect(() => {
        async function getData() {
            setClientList(null)
            let clients = await getClientList()
            setClientList(clients)
            setClient(clients[0])
        }
        getData()
        setSourceFile()
    }, [])

    useEffect(() => {
        if (client) {
            async function getData() {
                setPropertiesList(null)
                let propertyList = await getPropertiesList(client);
                setPropertiesList(propertyList)
            }
            getData()
        }
        else {
            setPropertiesList([])
        }
    }, [client])

    useEffect(() => {
        if (client && propertiesList) {
            getEmailData()
        }

    }, [propertiesList, weekRange])

    function prevWeek() {
        let tempDate = new Date(weekRange[0]);
        tempDate.setDate(tempDate.getDate() - 1);
        setWeekRange([startOfWeek(tempDate), endOfWeek(tempDate)]);
    }

    function nextWeek() {
        let tempDate = new Date(weekRange[1]);
        tempDate.setDate(tempDate.getDate() + 1);
        setWeekRange([startOfWeek(tempDate), endOfWeek(tempDate)]);
    }

    async function getEmailData() {
        setProcessingData(true)
        let client_name = client['url'].split('.')
        client_name = client_name[0]
        let start = moment(weekRange[0]).format('YYYY-MM-DD')
        let end = moment(weekRange[1]).format('YYYY-MM-DD')
        let res = await axios.get('/inbox', { params: { client_name, start, end } })
        let data = res.data

        let tempProperties = [...propertiesList]

        function getMail(sales, date, property) {
            let col_date = moment(date).format('YYYY-MM-DD');
            for (let i of sales) {
                let file_Date = moment(i['file_Date']).format('YYYY-MM-DD');
                if (moment(col_date).isSame(file_Date)) {
                    return {
                        main_table_id: i['_id'],
                        timestamp: i['file_Date'],
                        process_status: i['process_status'],
                        client: i['client'],
                        corporation: i['corporation'],
                        CorpID: property['id']
                    };
                }
            }
            return null;
        }

        tempProperties.forEach(i => {
            let sales = data.filter(j => j['corporation'] === i['name'])
            datesInRange.forEach(date => {
                let mail = getMail(sales, date, i)
                i[date] = {
                    mail,
                    color: getColor(mail),
                    variant: getVariant(mail),
                    disabled: getDisabled(mail),

                }
            })
        })

        setTableData(tempProperties)
        setProcessingData(false)
    }

    async function getallattachments(fileData) {
        setBackdropState(true)
        setSelectedEmail(fileData)
        let res = await axios.get(`/get_all_attachments`, { params: { id: fileData['main_table_id'] } })
        setSourceFile(res.data)
        setBackdropState(false)
    }

    function closeReviewDialog() {
        setSourceFile()
        setDataFrames([])
        setRemovedDataFrames([])
        setRemovedLineItemTrack([])
    }

    const HandleReviewPostButton = async () => {
        setBackdropState(true)
        let user = JSON.parse(sessionStorage.getItem('user'))
        let remove_dfms_id = RemovedDataFrames.map(i=>i['id'])
        let removed_lineItems = RemovedLineItemTrack.map(i=>i['lineitem_desc'])

        const { clientID, clientSecret } = client
        let postedBy = user['email']

        let body = {
            ...selectedEmail,
            clientID,
            clientSecret,
            remove_dfms_id,
            removed_lineItems,
            postedBy
        }
        await axios.post('http://54.210.195.87:8000/label_classification', body)
        closeReviewDialog()
        getEmailData()
        enqueueSnackbar('Added to queue')
        setBackdropState(false)
    }

    return (
        <SnackbarProvider>
            <Toolbar variant='dense' sx={{ position: 'relative' }} >
                {clientList ?
                    <>
                        <Stack direction={'row'} gap={1} alignItems={'center'} flexGrow={1}>
                            <ClientsList list={clientList} client={client} updateClient={setClient} />
                            <IconButton disabled={!client || !weekRange} size='small' onClick={prevWeek}>
                                <ArrowBack fontSize='small' />
                            </IconButton>
                            <DateRangePicker
                                oneTap
                                showOneCalendar
                                hoverRange="week"
                                value={weekRange}
                                ranges={[]}
                                onChange={(v) => setWeekRange(v)}
                                disabled={!client}
                                renderValue={([start, end]) => {
                                    return format(start, 'MMM, d') + ' - ' + format(end, 'MMM, d');
                                }}
                                placeholder="Select Week Range"
                            />
                            <IconButton disabled={!client || !weekRange} size='small' onClick={nextWeek}>
                                <ArrowForward fontSize='small' />
                            </IconButton>
                        </Stack>
                        <InboxLegend />
                    </>
                    :
                    <Box top={0} bottom={0} left={0} right={0} position={'absolute'}>
                        <LinearProgress color='header' />
                    </Box>

                }
            </Toolbar>
            {clientList &&
                <Box flexGrow={1} width={'100%'}>
                    {(propertiesList) ?
                        <VirtualizedTable
                            datesInRange={datesInRange}
                            processedData={tableData}
                            getallattachments={getallattachments}
                            loading={processingData}
                        />
                        :
                        <Loading />
                    }
                </Box>
            }
            <Dialog TransitionComponent={DialogTransition} fullScreen open={Boolean(sourceFile)} onClose={closeReviewDialog}>
                <AppBar position='static'>
                    <Toolbar variant='dense' sx={{ gap: 2 }}>
                        <Typography variant='h6' flexGrow={1}>
                            Review
                        </Typography>
                        <Button
                            color='success'
                            disabled={[1, 2].includes(selectedEmail?.['process_status'])}
                            size='small'
                            variant='contained'
                            onClick={HandleReviewPostButton}
                        >
                            Post
                        </Button>
                        <IconButton color='inherit' onClick={closeReviewDialog}>
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ p: 1, height: '100%', bgcolor: 'hsla(215, 15%, 97%, 0.5)' }} >
                    <SubmitPage
                        files={sourceFile}
                        dataFrames={dataFrames}
                        setDataFrames={setDataFrames}

                        setRemovedDataFrames={setRemovedDataFrames}
                        setRemovedLineItemTrack={setRemovedLineItemTrack}
                    />
                    <Backdrop open={backDropState} sx={{ zIndex: zIndex.drawer + 1 }}>
                        <CircularProgress />
                    </Backdrop>
                </DialogContent>
            </Dialog>
            <Backdrop open={backDropState} sx={{ zIndex: zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </SnackbarProvider>
    )
}

export default Inbox

function InboxLegend() {
    return (
        <Stack direction={'row'} gap={1}>
            <Chip size='small' label='Pending' color='primary' />
            <Chip size='small' label='Success' color='success' />
            <Chip size='small' label='Hold' color='warning' />
            <Chip sx={{ opacity: 0.6 }} size='small' label='Failed' color='error' />
            <Chip sx={{ opacity: 0.6 }} size='small' label='Progress' color='success' />
        </Stack>
    )
}

function getColor(mail) {
    if (mail) {
        let status = mail['process_status'];
        switch (status) {
            case 0:
                return 'primary';
            case 1:
                return 'success';
            case 2:
                return 'warning';
            case 3:
                return 'success';
            case -1:
                return 'error';
        }
    }
    return 'info';
}

function getDisabled(mail) {
    if (mail) {
        let status = mail['process_status'];
        switch (status) {
            case -1:
                return true;
            case 3:
                return true;
            default:
                return false;
        }
    }
    return !mail;
}

function getVariant(mail) {
    if (mail) {
        return 'contained';
    }
    return 'outlined';
}