import React, { useState } from 'react'

import { Table, Button, Space, InputNumber, Input } from "antd"

import { Stack, IconButton, Dialog, DialogContent, Autocomplete, FormControl, FormLabel, RadioGroup, FormHelperText, Radio, FormControlLabel, TextField, DialogActions, Button as MUIButton, DialogTitle, Typography, ListItem, ListItemText } from "@mui/material";
import { ArrowDownward, ArrowForward, ArrowUpward, Dehaze, Delete, ShowChart } from "@mui/icons-material";
import { green } from '@mui/material/colors';

import { confirm } from '../../Confirm'
import { updateIntegers } from '../../../Tools/tools';
import { PlusOutlined } from '@ant-design/icons';

const ExpandPanel = ({ data = {}, updateData, fileComponent, label }) => {

    const [moveLineItem, setMoveLineItem] = useState({
        lineItem: null,
        group: null,
        open: false
    }) //User Clicked line item

    const [addLineItem, setAddLineItem] = useState({
        amount: 0,
        stat: 0,
        lineItem: "",
        open: false,
        group: null
    })

    const [values, setValues] = useState({
        "label": null, //This is the user want to this label
        "group": null // This is the user want to move to this group related that label
    })

    const groups = {
        detail: 'lineItemsGroup_Detailed',
        summary: 'lineItemsGroup_summary'
    }

    function closeMoveDialog() {
        setValues({
            lineItem: null,
            group: null
        })
        setMoveLineItem({
            lineItem: null,
            group: null,
            open: false
        })
    }

    function closeAddDialog() {
        setAddLineItem({
            amount: 0,
            stat: 0,
            lineItem: "",
            open: false,
            group: null
        })
    }

    const handleAddLineItem = () => {
        let { open, group, ...rest } = addLineItem
        let selectedGroup = groups[group]
        let selectedLabel = label
        let selectedDept = label['Dept']

        let updatedData = { ...data };

        let tempLabelList = updatedData[selectedDept].map(l => {
            if (l === selectedLabel) {
                let updatedLabel = { ...l };
                updatedLabel[selectedGroup] = [...updatedLabel[selectedGroup], { ...rest }];
                return updateIntegers(group, selectedGroup, updatedLabel)
            }
            return l;
        });

        updatedData[selectedDept] = tempLabelList;
        updateData(updatedData);
        closeAddDialog()
    };

    const handleMoveLineItem = () => {
        let group = moveLineItem['group']
        let selectedLabel = values['label']
        let selectedGroup = values['group']
        let selectedDept = selectedLabel['Dept']

        let updatedData = { ...data };

        let tempLabelList = updatedData[selectedDept].map(l => {
            if (l === selectedLabel) {
                let updatedLabel = { ...l };
                updatedLabel[selectedGroup] = [...updatedLabel[selectedGroup], moveLineItem['lineItem']];
                return updateIntegers(group, selectedGroup, updatedLabel)
            }
            return l;
        });

        updatedData[selectedDept] = tempLabelList;

        //Delete process
        deleteLineItem(group, updatedData, moveLineItem['lineItem'])
    };

    async function handleDeleteLineItem(lineItem, group) {
        if (await confirm("Are your sure")) {
            let updatedData = { ...data };
            deleteLineItem(group, updatedData, lineItem)
        }
    }

    function deleteLineItem(group, data, lineItem) {
        let dept = label['Dept']
        let selectedGroup = groups[group]

        let tempLabelList = data[dept].map(l => {
            if (l === label) {
                let updatedLabel = { ...l };
                updatedLabel[selectedGroup] = updatedLabel[selectedGroup].filter(item => item !== lineItem);
                return updateIntegers(group, selectedGroup, updatedLabel)
            }
            return l;
        });
        data[dept] = tempLabelList;
        updateData(data);
        closeMoveDialog()
    }


    function DetailsTable({ name = "", data }) {
        return (
            <Table
                size='small'
                style={{ flexGrow: 1 }}
                dataSource={data}
                pagination={false}
                bordered
                title={() =>
                    <Stack direction={'row'} justifyContent={'space-between'} p={1}>
                        {name}
                        <Button size='small' shape='circle' onClick={() => setAddLineItem(prev => ({ ...prev, open: true, group: name.toLowerCase() }))}>
                            <PlusOutlined />
                        </Button>

                    </Stack>
                }
                columns={[
                    {
                        title: "Line Item",
                        dataIndex: 'lineItem',
                    },
                    {
                        title: "Amount",
                        dataIndex: 'amount'
                    },
                    {
                        title: "Stat",
                        dataIndex: 'stat'
                    },
                    {
                        title: "Action",
                        align: 'center',
                        width: 72,
                        render: (_, record) => (
                            <Space>
                                <Button size='small' type='link' onClick={() => setMoveLineItem({ lineItem: record, group: name.toLowerCase(), open: true })}>Move</Button>
                                <Button size='small' type='text' onClick={() => handleDeleteLineItem(record, name.toLowerCase())} danger>Delete</Button>
                            </Space>
                        )
                    }
                ]}
            />
        )
    }

    function handleChangeAddLineItem(key, value) {
        setAddLineItem(prev => ({ ...prev, [key]: value }))
    }

    return (
        <>
            <Stack direction={'row'} gap={1} p={0.5}>
                <DetailsTable name={'Detail'} data={label[groups['detail']]} />
                {!fileComponent && <DetailsTable name={'Summary'} data={label[groups['summary']]} />}
            </Stack>

            <Dialog fullWidth maxWidth='sm' open={moveLineItem['open']} onClose={closeMoveDialog}>
                <DialogTitle>
                    Move To
                </DialogTitle>
                <DialogContent sx={{ height: '90vh' }}>

                    <Stack alignItems={'center'} border={1} pl={1} pr={1} borderColor={'divider'}>

                        <ListItem disableGutters>
                            <ListItemText
                                primary={
                                    <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                                        {label['Label']}

                                        <ArrowDownward fontSize='small' />
                                        ${moveLineItem['lineItem']?.['amount']}
                                        <ShowChart fontSize='small' />
                                        {moveLineItem['lineItem']?.['stat']}
                                    </Stack>
                                }
                                secondary={
                                    `${moveLineItem['lineItem']?.['lineItem']}
                                    (${moveLineItem['group']})`
                                }
                            />
                        </ListItem>

                        <ArrowDownward fontSize='large' />
                        <Stack width={'100%'} alignItems={'start'}>
                            <FormControl margin='dense'>
                                <FormLabel>Type</FormLabel>
                                <RadioGroup row onChange={(e) => setValues(prev => ({ ...prev, group: e.target.value }))}>
                                    <FormControlLabel value={groups['detail']} control={<Radio />} label="Detail" />
                                    <FormControlLabel value={groups['summary']} control={<Radio />} label="Summary" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>

                        <Autocomplete
                            size='small'
                            fullWidth
                            options={Object.values(data).flat() || []}
                            getOptionLabel={(option) => option.Label}
                            renderInput={(params) =>
                                <TextField {...params} margin='dense' label="Labels"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                                                    <ArrowUpward fontSize='small' />
                                                    ${moveLineItem['lineItem']?.['amount']}
                                                    <ShowChart fontSize='small' />
                                                    {moveLineItem['lineItem']?.['stat']}
                                                </Stack>
                                            </>
                                        )
                                    }}
                                />}
                            onChange={(e, v) => setValues(prev => ({ ...prev, label: v }))}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={closeMoveDialog} color='error'>Cancel</MUIButton>
                    <MUIButton onClick={handleMoveLineItem} disabled={!values['group'] || !values['label']} >Confirm</MUIButton>
                </DialogActions>
            </Dialog>


            <Dialog fullWidth maxWidth='sm' open={addLineItem['open']} onClose={closeAddDialog}>
                <DialogTitle>
                    Add New
                </DialogTitle>
                <DialogContent>

                    <Stack direction={'row'} gap={1}>
                        <Input placeholder="Line Item" onChange={(e) => handleChangeAddLineItem('lineItem', e.target.value)} />
                        <Stack direction={'row'} gap={1}>
                            <InputNumber placeholder="Amount" onChange={(v) => handleChangeAddLineItem('amount', v)} />
                            <InputNumber placeholder="Stat" onChange={(v) => handleChangeAddLineItem('stat', v)} />
                        </Stack>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={closeAddDialog} color='error'>Cancel</MUIButton>
                    <MUIButton onClick={handleAddLineItem} >Confirm</MUIButton>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ExpandPanel