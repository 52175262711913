import React, { useState } from 'react'
import CustomTable from '../CustomTable/CustomTable'
import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    Collapse,
    Dialog,
    DialogContent,
    Fade,
    FormControl,
    FormControlLabel,
    IconButton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Typography,
    Zoom
} from '@mui/material';
import { ArrowForward, Clear, Receipt, TableViewOutlined } from '@mui/icons-material'
import axios from 'axios'
import { createSale, deleteSale, generateToken, getLines, updateSale } from '../../API/NimbleAPI'
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import CustomBackdrop from '../CustomBackdrop'
import DialogTransition from '../DialogTransition'
import { FileComponent } from './FileComponent/FileComponent'
import AutoSizer from "react-virtualized-auto-sizer";
import Loading from '../Loading'
import ReviewTable from './ReviewTable/ReviewTable'
import { AutoComplete } from 'antd'
import LabelLinking from './LabelLinking/LabelLinking';
import { confirm } from '../Confirm'

const uuid = require('uuid');

const Sales = ({ data = [], loading, columns, getTableData, RenderFooterButtons, callback }) => {

    const [nimbleLabels, setNimbleLabels] = useState([])
    const [labelLinking, setLabelLinking] = useState(false)
    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [selectedCorpID, setSelectedCorpID] = useState('')
    const [labelsList, setLabelsList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(0)
    const [fileComponent, setFileComponent] = useState(true)
    const [selectedReceivable, setSelectedReceivable] = useState()

    const [attachmentFiles, setAttachmentFiles] = useState()
    const [openReviewDialog, setOpenReviewDialog] = useState(false)

    const [reviewTableList, setReviewTableList] = useState()
    const [sourceReviewTableList, setSourceReviewTableList] = useState()
    const [selectedLabels, setSelectedLabels] = useState([])

    const [scrollIndex, setScrollIndex] = useState(0)

    const selectedReceivableIndex = getIndex()

    async function reCheckLabelLinking(CorpID) {
        let res = await axios.get('/receivables/re_check_label_linking', { params: { CorpID } })
        return res.data
    }

    async function getReceivableData(receivable) {
        setOpenBackDrop(true)
        let { CorpID } = receivable
        await resetState()
        let label_linking = await reCheckLabelLinking(CorpID)
        if (label_linking['new_label_status']) {
            getAllAttachment(receivable)
            setOpenReviewDialog(true)
            setOpenBackDrop(false)
            setSelectedReceivable(receivable)
            let { tabWiseData, saleID } = await getTableData(receivable)
            if (saleID) {
                receivable['saleID'] = saleID
            }
            setReviewTableList(tabWiseData)
            setSourceReviewTableList(tabWiseData)
        }
        else if (label_linking['remove_label_status']) {
            setOpenBackDrop(false)
            let new_labels = label_linking['added_labels']
            new_labels = new_labels.map(i => i['Label'])
            console.log(new_labels)
            enqueueSnackbar(`New label is added, ${new_labels.join(" ")}. Please check the label linking`, { variant: 'warning', })
        }
        else {
            setOpenBackDrop(false)
            enqueueSnackbar("Labels updated")
        }
    }

    async function getAllAttachment(receivable) {
        let res = await axios.get(`/get_all_attachments`, { params: { id: receivable['main_table_id'] } })
        setAttachmentFiles(res.data)
    }

    function checkPayload(body) {
        let values = []

        Object.entries(body).forEach(([key, value]) => {
            if (key !== "dailyInputEntry") {
                values = [...values, ...value]
            }
        })

        return values.every(i => i['lineID'])
    }

    async function handleCreateSale() {
        let body = GenerateFinalResponse(data, selectedReceivable)

        if (checkPayload(body)) {
            if (await confirm("Are your sure")) {
                setOpenBackDrop(true)
                const { clientID, clientSecret } = selectedReceivable

                let token = await generateToken(clientID, clientSecret, enqueueSnackbar)

                let res = await createSale(body, token, enqueueSnackbar)
                let status = res.statusCode

                switch (status) {
                    case 200:
                        await save2verified_table(res, body)
                        break
                    case 500:
                        enqueueSnackbar(res.status, { variant: 'error' })
                        break
                    case 401:
                        enqueueSnackbar(res.status, { variant: 'warning' })
                        break
                    default:
                        enqueueSnackbar(res.status)
                        break
                }
            }
        }
        else {
            setFileComponent(false)
            enqueueSnackbar("Make sure that each label is linked with nimble label", { variant: 'warning' })
        }
        setOpenBackDrop(false)
    }

    async function handleUpdateSale() {
        setOpenBackDrop(true)
        const { clientID, clientSecret, saleID } = selectedReceivable
        let token = await generateToken(clientID, clientSecret, enqueueSnackbar)
        let body = GenerateFinalResponse(data, selectedReceivable)
        body['dailyInputEntry']['id'] = saleID
        let res = await updateSale(body, token, enqueueSnackbar)
        let status = res.statusCode

        switch (status) {
            case 200:
                await updateVerifiedTable(res, body)
                break
            case 500:
                enqueueSnackbar(res.status, { variant: 'error' })
                break
            case 401:
                enqueueSnackbar(res.status, { variant: 'warning' })
                break
            default:
                enqueueSnackbar(res.status)
                break
        }
        setOpenBackDrop(false)
    }

    async function handleDeleteSale() {
        if (await confirm("Are your sure")) {
            setOpenBackDrop(true)
            const { clientID, clientSecret } = selectedReceivable
            let token = await generateToken(clientID, clientSecret, enqueueSnackbar)
            let body = { "id": selectedReceivable['saleID'] }
            let res = await deleteSale(body, token, enqueueSnackbar)
            let status = res.statusCode

            switch (status) {
                case 200:
                    deleteVerifiedTable()
                    break
                case 500:
                    enqueueSnackbar(res.status, { variant: 'error' })
                    break
                case 401:
                    enqueueSnackbar(res.status, { variant: 'warning' })
                    break
                default:
                    enqueueSnackbar(res.status)
                    break
            }
            setOpenBackDrop(false)
        }
    }

    async function save2verified_table(res, payload) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let insert_data = {
            response: res,
            payload: payload,
            extraction_id: selectedReceivable['_id'],
            user: user.username,
            tables_data: reviewTableList
        }
        await axios.post('/add_to_verified_table', insert_data)
        enqueueSnackbar('Sale created successfully', { variant: 'success' })
        if (selectedReceivableIndex + 1 !== data.length) {
            if (data.length === 2 && selectedReceivableIndex === 1) {
                moveToPrevious("event")
            }
            else {
                movetoNext("event")
            }
        }
        callback()
    }

    async function updateVerifiedTable(res, payload) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let insert_data = {
            response: res,
            payload: payload,
            extraction_id: selectedReceivable['_id'],
            user: user.username,
            tables_data: reviewTableList
        }
        await axios.post('/update_verified_table', insert_data)
        enqueueSnackbar('Sale Updated successfully', { variant: 'success' })
    }

    async function deleteVerifiedTable() {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let extraction_id = selectedReceivable['_id']
        await axios.patch('/inactive_verified_table', null, { params: { extraction_id, user } })
        enqueueSnackbar('Sale Deleted successfully', { variant: 'success' })
        if (selectedReceivableIndex + 1 !== data.length) {
            if (data.length === 2 && selectedReceivableIndex === 1) {
                moveToPrevious("event")
            }
            else {
                movetoNext("event")
            }
        }
        callback()
    }

    function GenerateFinalResponse() {

        const { bill_date, CorpID } = selectedReceivable

        let originalDate = new Date(bill_date);
        let localDate = new Date(originalDate.getTime() - originalDate.getTimezoneOffset() * 60000);
        let billDate = localDate.toISOString().split('T')[0];

        let skt = {
            "dailyInputEntry": {
                "corpID": CorpID,
                "id": "",
                "revenuveJournalID": "",
                "saleDate": billDate,
                "status": "",
                "assignedTo": "",
                "approvalStatus": "",
                "comment": "Api Cal",
                "pcid": ""
            },
            "revenue": [],
            "receipt": [],
            "arDetails": [],
            "statistics": []
        }
        Object.values(reviewTableList).map(it => {
            it.map(item => {
                item['lineItemsGroup_Detailed']?.map(i => {
                    let amount = i['amount']
                    // amount = isNaN(amount) ? 0 : Number(amount);
                    skt[item['Dept']].push({ 'lineID': item['lineID'], 'amount': amount })
                })
            })
        })
        return skt
    }

    async function getLabels() {
        let res = await axios.get('/labels')
        return res.data
    }

    async function openLabelLinking(receivable) {
        let { _id, CorpID, clientID, clientSecret, label_linking_status } = receivable
        setOpenBackDrop(true)
        setNimbleLabels([])
        setSelectedCorpID(CorpID)

        let res;
        let tempSnapLabels;

        if (label_linking_status) {
            let reCheck_label_linking = await reCheckLabelLinking(CorpID)

            res = await axios.get('/receivables/property/labels', { params: { CorpID } })
            let property_labels = res.data

            if (reCheck_label_linking['new_label_status']) {
                tempSnapLabels = property_labels
            }
            else {
                let new_labels = reCheck_label_linking['added_labels']
                tempSnapLabels = [...new_labels, ...property_labels]
            }
        }
        else {
            let labels = await getLabels()
            tempSnapLabels = labels
        }

        const token = await generateToken(clientID, clientSecret, enqueueSnackbar)
        let body = { "corpID": CorpID, "profitCenterID": "" }

        res = await getLines(body, token, enqueueSnackbar)
        let tempNimbleLabels = res.lines

        tempNimbleLabels = addUniqueKey(tempNimbleLabels)
        // tempSnapLabels = addUniqueKey(tempSnapLabels)

        function addUniqueKey(data) {
            data?.forEach(i => {
                i['uniqueID'] = uuid.v4()
            })
            return data
        }

        setNimbleLabels(tempNimbleLabels)
        setLabelsList(tempSnapLabels)
        setSelectedReceivable(receivable)
        setLabelLinking(true)

        setOpenBackDrop(false)
    }

    async function resetState() {
        setAttachmentFiles()
        setSelectedReceivable()
        setSelectedDepartment(0)
        setReviewTableList([])
    }

    function closeReviewDialog() {
        setOpenReviewDialog(false)
        resetState()
    }

    function closeLabelLinking() {
        setLabelLinking(false)
        setLabelsList()
        setNimbleLabels()
        setScrollIndex(0)
        setSelectedLabels([])
    }

    const activeColor = "#F08080"

    const tabStyles = {
        color: 'inherit',
        '&.Mui-selected': {
            color: activeColor,
        },
    }

    const _TAB_DELAY = 120

    function movetoNext(e, change = 1) {
        let index = getIndex()
        if (index !== -1) {
            let newReceivable = data[index + change]
            if (newReceivable) {
                if (
                    newReceivable.extraction_status !== 0 &&
                    newReceivable.label_linking_status
                ) {
                    getReceivableData(data[index + 1])
                }
                else {
                    movetoNext(e, change + 1)
                }
            }
            else {
                enqueueSnackbar("Max limit reached")
            }
        }
    }

    function moveToPrevious(e, change = 1) {
        let index = getIndex()
        if (index !== -1) {
            let newReceivable = data[index - change]
            if (newReceivable) {
                if (
                    newReceivable.extraction_status !== 0 &&
                    newReceivable.label_linking_status
                ) {
                    getReceivableData(data[index - 1])
                }
                else {
                    moveToPrevious(e, change - 1)
                }
            }
            else {
                enqueueSnackbar("Max limit reached")
            }
        }
    }

    function getIndex() {
        if (selectedReceivable) {
            return data.findIndex(i => i['_id'] === selectedReceivable?.['_id'])
        }
        return -1
    }

    async function saveLabelList() {
        setOpenBackDrop(true)

        let user = JSON.parse(sessionStorage.getItem('user'))
        let payload = { 'finalList': labelsList, 'corpID': selectedCorpID, user }

        await axios.post('/properties/label_linking', payload)
        setOpenBackDrop(false)
        closeLabelLinking()

    }

    function checkLinkingStatus() {
        return !labelsList?.every(i => i.hasOwnProperty('lineID')) || false
    }

    function scrollToLabel(v) {
        let index = labelsList.findIndex(i => i['Label'] === v)
        setScrollIndex(index)
    }

    function handleHideZeroes(e) {
        let checked = e.target.checked
        if (reviewTableList) {
            if (checked) {
                let tempReviewTableList = { ...reviewTableList };

                tempReviewTableList = Object.fromEntries(
                    Object.entries(tempReviewTableList).map(([key, value]) => {
                        return [key, value?.filter(i => {
                            i['lineItemsGroup_summary'] = i['lineItemsGroup_summary']?.filter(item => (item['amount'] !== 0 || item['stat'] !== 0));
                            i['lineItemsGroup_Detailed'] = i['lineItemsGroup_Detailed']?.filter(item => (item['amount'] !== 0 || item['stat'] !== 0));
                            return i['lineItemsGroup_summary']?.length !== 0 ||
                                i['lineItemsGroup_Detailed']?.length !== 0
                        })];
                    })
                );

                setReviewTableList(tempReviewTableList);
            }
            else {
                setReviewTableList(sourceReviewTableList)
            }

        }
    }

    function addToToLabelList(labels, v) {
        labels = new Set(labels)
        let tempLabelList = [...labelsList]
        Array.from(labels).forEach(label => {
            if (v) {
                let index = tempLabelList.findIndex(i => i['_id'] == label['_id'])
                if (index !== -1) {
                    let { uniqueID, ...rest } = v
                    let newLabel = {
                        ...label,
                        ...rest
                    }
                    tempLabelList[index] = newLabel
                }
            }
        })
        setSelectedLabels([])
        setLabelsList(tempLabelList)
    }


    function tempFunc1() {
        let tempLabelList = [...labelsList]
        tempLabelList.forEach(label => {
            if (!label['lineID']) {
                let randomIndex = Math.floor(Math.random() * nimbleLabels.length)
                let v = nimbleLabels[randomIndex]
                let { uniqueID, ...rest } = v
                let newLabel = {
                    ...label,
                    ...rest
                }

                let index = tempLabelList.findIndex(i => i['_id'] == label['_id'])

                if (index !== -1) {
                    tempLabelList[index] = newLabel
                }
            }
        })
        setSelectedLabels([])
        setLabelsList(tempLabelList)
    }

    return (
        <SnackbarProvider style={{ maxWidth: '600px' }}>
            <CustomTable
                list={data}
                columns={columns}
                action
                loading={loading}
                ActionComponent={(rowData, rowIndex) =>
                    <Stack direction={'row'}>
                        <IconButton
                            size='small'
                            color='primary'
                            disabled={rowData.extraction_status == 0 || rowData.label_linking_status == false}
                            onClick={() => getReceivableData(rowData)}
                        >
                            <Receipt fontSize="small" />
                        </IconButton>
                        <IconButton
                            size='small'
                            color={rowData.label_linking_status == true ? 'success' : 'error'}
                            onClick={() => openLabelLinking(rowData)}
                        >
                            <TableViewOutlined fontSize="small" />
                        </IconButton>
                    </Stack>
                }
            />

            <Dialog TransitionComponent={DialogTransition} fullScreen open={openReviewDialog} onClose={closeReviewDialog}>

                <AppBar position='static' variant='outlined'>
                    <Toolbar variant='dense' sx={{ justifyContent: 'space-between', alignItems: 'center', }}>
                        <Tabs
                            TabIndicatorProps={{ sx: { bgcolor: activeColor } }}
                            value={selectedDepartment}
                            onChange={(e, v) => setSelectedDepartment(v)}
                        >
                            <Zoom in style={{ transitionDelay: `${_TAB_DELAY}ms` }}>
                                <Tab sx={tabStyles} label="Revenue" />
                            </Zoom>
                            <Zoom in style={{ transitionDelay: `${_TAB_DELAY + (1 * _TAB_DELAY)}ms` }}>
                                <Tab sx={tabStyles} label="Cash/Card Receipts" />
                            </Zoom>
                            <Zoom in style={{ transitionDelay: `${_TAB_DELAY + (2 * _TAB_DELAY)}ms` }}>
                                <Tab sx={tabStyles} label="AR Details" />
                            </Zoom>
                            <Zoom in style={{ transitionDelay: `${_TAB_DELAY + (2 * _TAB_DELAY)}ms` }}>
                                <Tab sx={tabStyles} label="Statistics" />
                            </Zoom>
                            <Zoom in style={{ transitionDelay: `${_TAB_DELAY + (4 * _TAB_DELAY)}ms` }}>
                                <Tab sx={tabStyles} label="Verification" />
                            </Zoom>
                        </Tabs>

                        <Stack direction={'row'} gap={3} alignItems={'center'}>
                            <FormControlLabel control={<Checkbox onChange={handleHideZeroes} color='warning' sx={{ color: 'inherit' }} />} label="Hide Zeroes" />
                            {/* 
                            <Button variant='outlined' size='small' color='inherit' disabled={selectedReceivableIndex === 0} onClick={moveToPrevious}>
                                Prev
                            </Button>
                            <Button variant='outlined' size='small' color='inherit' disabled={selectedReceivableIndex + 1 === data.length} onClick={movetoNext}>
                                Next
                            </Button> 
                            */}
                            <IconButton color='inherit' onClick={closeReviewDialog}>
                                <Clear />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ p: 1, width: "100%", display: 'flex', gap: 1, overflow: "hidden", position: 'relative' }}>
                    {attachmentFiles ?
                        <>
                            <Box
                                height="100%"
                                width={fileComponent ? "55%" : 48}
                                style={{ transition: 'width 0.6s' }}
                                overflow={'hidden'}
                            >
                                <FileComponent files={attachmentFiles} fileComponent={fileComponent} setFileComponent={setFileComponent} />
                            </Box>

                            <Box
                                height="100%"
                                flexGrow={1}
                            >
                                <ReviewTable
                                    data={reviewTableList}
                                    updateData={setReviewTableList}
                                    tab={selectedDepartment}
                                    prevFState={fileComponent}
                                    RenderFooterButtons={() =>
                                        <Toolbar variant='dense' sx={{ justifyContent: 'center', gap: 2, height: 48 }}>

                                            {window.location.pathname === "/receivables" ?
                                                <Button variant='contained' color='success' onClick={handleCreateSale}>Create</Button>
                                                :
                                                <ButtonGroup>
                                                    <Button onClick={handleUpdateSale}>Update</Button>
                                                    <Button onClick={handleDeleteSale}>Delete</Button>
                                                </ButtonGroup>
                                            }

                                        </Toolbar>
                                    }
                                />
                            </Box>

                        </>
                        :
                        <Loading />
                    }

                </DialogContent>
                <CustomBackdrop open={openBackDrop} />
            </Dialog>

            <Dialog fullWidth maxWidth='md' open={labelLinking} TransitionComponent={DialogTransition} onClose={closeLabelLinking}>
                <Toolbar variant='dense' sx={{ gap: 2, alignItems: 'center' }}>
                    <Typography variant='h6' flexGrow={1} onDoubleClick={() => tempFunc1()}>
                        Label Linking
                    </Typography>
                    <Fade in={selectedLabels.length > 1}>
                        <Autocomplete
                            sx={{ width: '250px' }}
                            value={null}
                            size='small'
                            options={nimbleLabels || []}
                            getOptionLabel={(option) => option.lineName}
                            renderInput={(params) => <TextField {...params} variant='standard' placeholder="Nimble Labels" />}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option['uniqueID']}>
                                    {option.lineName}
                                </Box>
                            )}
                            onChange={(e, v) => addToToLabelList(selectedLabels, v)}
                        />
                    </Fade>
                    <Autocomplete
                        size='small'
                        sx={{ width: '250px' }}
                        options={labelsList?.map(i => i['Label']) || []}
                        renderInput={(params) => <TextField {...params} variant='standard' placeholder="Search" />}
                        onChange={(e, v) => scrollToLabel(v)}
                    />
                    <Button variant='contained' disabled={checkLinkingStatus()} onClick={saveLabelList}>
                        Send
                    </Button>
                </Toolbar>

                <DialogContent sx={{ p: 1, height: '90vh' }}>
                    <LabelLinking
                        addToToLabelList={addToToLabelList}
                        selectedLabels={selectedLabels}
                        setSelectedLabels={setSelectedLabels}
                        nimbleLabels={nimbleLabels}
                        labelsList={labelsList}

                        scrollIndex={scrollIndex}
                    />
                </DialogContent>
                <CustomBackdrop open={openBackDrop} />
            </Dialog>

            <CustomBackdrop open={openBackDrop} />

        </SnackbarProvider >
    )
}

export default Sales