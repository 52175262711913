import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { File } from '../../File/File';
import { enqueueSnackbar } from 'notistack';

const FilePanel = ({ files = [], updateDFs, start = false }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel, id) => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        updateDFs()
        if (isExpanded) {
            let res = await axios.get("/inbox/file/dataframes", { params: { id } })
            let data = res.data
            data.forEach(j => {
                j['df'].forEach(i => {
                    if (i.hasOwnProperty('stat') && ["string", "number"].includes(typeof (i['stat']))) {
                        i['stat'] = Math.floor(i['stat']);
                    }
                })
            })
            updateDFs(data)
            if (data.length === 0) {
                enqueueSnackbar('Cant find dataframes', { variant: 'warning' })
            }
        }
        else {
            updateDFs([])
        }
    };

    return (
        <Box height={'100%'} sx={{ overflowY: 'scroll' }} p={1} border={1} borderColor={'divider'}>
            {files.map((file, fileIndex) => {
                return (
                    <Accordion elevation={3} key={fileIndex} expanded={expanded === fileIndex} onChange={handleChange(fileIndex, file['_id'])}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            {file['name']}
                        </AccordionSummary>
                        <AccordionDetails style={{ pointerEvents: start ? 'none' : 'auto' }}>
                            <File s3_key={file['s3_key']} name={file['name']} height='500px' />
                        </AccordionDetails>
                    </Accordion>
                )
            }
            )}
        </Box>
    )
}

export default FilePanel