import axios from "axios";

const backend = axios

export async function getClientList() {
    try {
        // await backend.get("/setSession") //Starting Session
        const response = await backend.get("/clients")
        return response.data
    }
    catch (error) {
        console.log(error);
        return []
    }
}

export async function getPropertiesList(client) {
    try {
        // await backend.get("/setSession") //Starting Session
        const response = await backend.get("properties", { params: { clientID: client['clientID'] } })
        return response.data
    }
    catch (error) {
        console.log(error);
        return []
    }
}