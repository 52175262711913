import React, { useState } from 'react'

import {
    Box,
    Typography,
    Stack,
    Toolbar,
    Paper
} from '@mui/material'
import Header from './Header/Header'
import NavBar from './NavBar/NavBar'
import "./Layout.css"
import { Outlet } from 'react-router-dom'

function Layout() {
    const [headerContent, setHeaderContent] = useState(null);

    return (

        <Box height={'100vh'} width={'100%'}>
            <Box
                className='sidebar-wrapper'
                component={'aside'}
            >
                <NavBar />
            </Box>
            <Stack component={'main'} className='main-body-wrapper' height={'100%'} >
                <Header headerContent={headerContent} />

                <Stack overflow={'auto'} p={1.5} flexGrow={1} component={'section'} bgcolor={'hsla(215, 15%, 97%, 0.5)'} >
                    <Paper component={Stack} square sx={{ height: '100%', position: 'relative' }}>
                        <Outlet context={{ setHeaderContent }} />
                    </Paper>
                </Stack>

                <Toolbar variant='dense' component={'footer'} >
                    <Typography color={"#8a909d"}>
                        © {new Date().getFullYear()} Copyright Daily Sales
                    </Typography >
                </Toolbar>

            </Stack>
        </Box>
    )
}

export default Layout