import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

const ClientsList = ({ list, client, updateClient }) => {
    return (
        <Autocomplete
            sx={{ width: 250 }}
            value={client}
            size="small"
            options={list}
            getOptionLabel={option => option?.url.split(".")[0]}
            onChange={(event, value) => updateClient(value)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    placeholder="Select Client"
                    variant="standard"
                />
            }
        />
    )
}

export { ClientsList };