import { Autocomplete, Box, Checkbox, FormControlLabel, ListItemButton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
// import { Table } from 'rsuite';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { Button } from '@mui/material';
import 'react-virtualized/styles.css';

import './LabelLinking.css'

const LabelLinking = ({ labelsList = [], nimbleLabels = [], selectedLabels, setSelectedLabels, addToToLabelList = () => { }, scrollIndex }) => {

    return (
        <AutoSizer>
            {({ height, width }) => (
                <Table
                    width={width}
                    height={height}
                    headerHeight={0}
                    rowHeight={64}
                    scrollToIndex={scrollIndex}
                    scrollToAlignment='start'
                    rowCount={labelsList.length}
                    rowGetter={({ index }) => labelsList[index]}
                    rowStyle={(info) => ({ borderBottom: ![labelsList.length - 1].includes(info['index']) ? '0.5px solid lightgrey' : "none" })}
                    rowClassName={(props) => Boolean(selectedLabels.find(i => i['_id'] === labelsList[props.index]?.['_id'])) ? "selected-row" : ""}
                    gridStyle={{ scrollBehavior: 'smooth' }}
                >
                    <Column
                        width={32}
                        cellRenderer={({ rowData, rowIndex }) => <Typography variant='caption'>{rowIndex + 1}.</Typography>}
                    />

                    <Column
                        flexGrow={1}
                        width={1}
                        cellRenderer={({ rowData }) =>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(selectedLabels.find(i => i['_id'] === rowData['_id']))}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedLabels(prev => ([...prev, rowData]))
                                            }
                                            else {
                                                setSelectedLabels(prev => prev.filter(i => i['_id'] !== rowData['_id']))
                                            }
                                        }}
                                    />
                                }
                                label={<Typography>{rowData['Label']}</Typography>}
                                sx={{ width: '98%', height: '100%', ml: '0.5px' }}
                            />
                        }
                    />

                    <Column
                        flexGrow={1}
                        width={1}
                        cellRenderer={({ rowData, rowIndex, isScrolling }) =>
                            <Autocomplete
                                disabled={selectedLabels.length > 1}
                                value={nimbleLabels.find(j => j['lineID'] === rowData['lineID']) || null}
                                fullWidth
                                size='small'
                                options={nimbleLabels || []}
                                getOptionLabel={(option) => option.lineName}
                                renderInput={(params) => <TextField {...params} margin='normal' placeholder={selectedLabels.length > 1 ? "-" : "Nimble Labels"} />}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option['uniqueID']}>
                                        {option.lineName}
                                    </Box>
                                )}
                                onChange={(e, v) => addToToLabelList([...selectedLabels, rowData], v)}
                                blurOnSelect
                            />
                        }
                    />

                </Table>
            )}
        </AutoSizer>
    )
}

export default LabelLinking