import { Clear, ExpandMore, RemoveCircle } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, Chip, Collapse, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomTable from '../../CustomTable/CustomTable';
import { TransitionGroup } from 'react-transition-group';

import { Table } from 'rsuite';
import AutoSizer from "react-virtualized-auto-sizer";
import Loading from '../../Loading';

const { Column, HeaderCell, Cell } = Table;

const TablePanel = ({
    tables,
    updateTables,
    updateRemovesDF,
    updateRemovedLineItem
}) => {

    function removeDataFrame(id) {
        let tempTables = [...tables]
        let indexToRemove = tempTables.findIndex(i => i['id'] === id);
        if (indexToRemove !== -1) {
            updateRemovesDF(prev => [...prev, tempTables[indexToRemove]])
            tempTables.splice(indexToRemove, 1);
            updateTables([...tempTables]);
        }
    }


    function removeLineItem(table, lineItem) {
        let tempTables = [...tables];
        const index = table['df'].findIndex(item => item === lineItem);
        if (index !== -1) {
            updateRemovedLineItem(prev => [...prev, lineItem])
            table['df'].splice(index, 1);
            let obj_index = tempTables.findIndex(item => item['id'] === table['id']);
            tempTables[obj_index] = table; // Update the original table
            updateTables(tempTables);
        }
    }

    const list = tables?.sort((a, b) => {
        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        }
        return 0;
    })?.reduce((acc, table) => {
        const type = table['type'];
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(table);
        return acc;
    }, {});

    function getColumns(table) {
        let list = table['df']
        let columns = [
            {
                key: 'lineitem_desc',
                label: "Line Item",
                fixed: 'left'
            },
            {
                key: 'net_Amount',
                label: "Net Amount",
            },
            {
                key: "stat",
                label: 'Stat',
            }
        ];

        let existingKeys = new Set(columns.map(column => column.key));

        list.forEach(item => {
            Object.keys(item).forEach(key => {
                if (!existingKeys.has(key)) {
                    columns.push({
                        key: key,
                        label: key,
                    });
                    existingKeys.add(key);
                }
            });
        });
        return columns
    }

    return (
        <Box height={'100%'} sx={{ overflowY: 'scroll' }} border={1} borderColor={'divider'}>
            {list ?
                Object.entries(list).map(([type, item], index) => (
                    <Box p={1} key={index}>
                        <Accordion elevation={3} disableGutters>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ position: 'sticky', top: 0, zIndex: 3, bgcolor: 'white' }}>
                                <Typography variant='h6'>{type}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TransitionGroup>
                                    {item.map((table, tableIndex) => {
                                        return (
                                            <Collapse key={tableIndex}>
                                                <Card elevation={3} sx={{ mb: 1 }}>
                                                    <CardHeader
                                                        action={
                                                            <IconButton onClick={() => removeDataFrame(table['id'])} size='small' color='error'>
                                                                <Clear fontSize='small' />
                                                            </IconButton>
                                                        }
                                                        title={`${tableIndex + 1}.`}
                                                    />
                                                    <CardContent>
                                                        <Table
                                                            cellBordered
                                                            bordered
                                                            autoHeight
                                                            data={table['df']}
                                                        >
                                                            {getColumns(table).map((column, columnIndex) =>
                                                                <Column key={columnIndex} fixed={column['fixed']} flexGrow={column['flexGrow']} fullText>
                                                                    <HeaderCell>{column['label']}</HeaderCell>
                                                                    <Cell dataKey={column['key']} />
                                                                </Column>
                                                            )}
                                                            <Column width={64} fixed={'right'} align="center" verticalAlign='center'>
                                                                <HeaderCell>Action</HeaderCell>
                                                                <Cell
                                                                    style={{ padding: 0 }}
                                                                    children={(rowData, rowIndex) =>
                                                                        <IconButton disabled={table['df'].length === 1} size='small' color='warning' onClick={() => removeLineItem(table, rowData)}>
                                                                            <RemoveCircle fontSize='small' />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                            </Column>
                                                        </Table>
                                                    </CardContent>
                                                </Card>
                                            </Collapse>
                                        )
                                    })}
                                </TransitionGroup>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))
                :
                <Loading />
            }
        </Box>
    )
}

export default TablePanel