import React, { useState } from 'react';
import './Login.css'
import img from './img.jpg'
import axios from "axios";
import { Button, Typography, TextField, Link, Box, Grid, Paper, Stack, IconButton } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [incorrectPassword, setIncorrectPassword] = useState(false)
    const Navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let credentials = {
            email: data.get('email'),
            password: data.get('password'),
        };

        axios({
            url: '/login',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(credentials),
        })
            .then((res) => {
                let data = res.data
                let status = data['status']
                console.log(status)
                switch (status) {
                    case 100:
                        console.log("Login")
                        sessionStorage.setItem('user', JSON.stringify(data['user']))
                        Navigate(0)
                        break
                    case 404:
                        setInvalidEmail(true)
                        break
                    case 403:
                        setIncorrectPassword(true)
                        break
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    return (

        <Grid container component="main" sx={{ height: '100vh' }} >

            <Grid sx={{ position: 'relative' }} item xs={12} sm={8} md={5} component={Stack} direction='column' alignItems='center' justifyContent='center'>

                <Typography>
                    <b>Welcome to</b>
                </Typography>
                <Typography variant='h5'>
                    <b>NIMBLE <span style={{ color: '#112e97' }}>PROPERTY</span></b>
                </Typography>
                <Typography variant="body2" color="text.secondary" m={1}>
                    Enter your login details
                </Typography>

                <Box component="form" onSubmit={handleSubmit} className='login-form-wrapper'>
                    <TextField
                        className='inputRounded'
                        size='small'
                        sx={{ width: '100%' }}
                        margin="normal"
                        name="email"
                        error={invalidEmail}
                        helperText={invalidEmail && "Invalid Email"}
                        InputProps={{
                            startAdornment: (
                                <AccountCircleOutlinedIcon color="action" sx={{ marginRight: '10px' }} />
                            ),
                            placeholder: 'Email',
                            style: { fontSize: '15px' }
                        }}
                        onClick={() => setInvalidEmail(false)}
                    />

                    <TextField
                        className='inputRounded'
                        size='small'
                        margin="normal"
                        sx={{ width: '100%' }}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        error={incorrectPassword}
                        helperText={incorrectPassword && "Incorrect Password"}
                        InputProps={{
                            startAdornment: (
                                <HttpsOutlinedIcon color="action" sx={{ marginRight: '10px' }} />
                            ),
                            endAdornment: (
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            ),
                            placeholder: 'Password',
                            style: { fontSize: '15px' }
                        }}
                        onClick={() => setIncorrectPassword(false)}
                    />

                    <Button
                        variant="contained"
                        type='submit'
                        className='login-btn'
                        sx={{ borderRadius: '50px', backgroundColor: ' #112e97', width: '15ch', m: 2 }}
                    >
                        <b>Login</b>
                    </Button>
                </Box>

                <Link href="#" variant="body2">
                    Forgot password?
                </Link>

                <Box className='copyright'>
                    <Typography variant="body2" color="text.secondary" align="center" >
                        {'Copyright © '}
                        <Link color="inherit" href="/">
                            Daily Sales
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Box>

            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.6
                }}
            />
        </Grid>

    );
}