import React, { useEffect, useState } from 'react';
import Sales from '../../Components/Sales/Sales';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import axios from 'axios';
import Loading from '../../Components/Loading';

const ProcessedSales = () => {
    const [data, setData] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setData()
        try {
            let res = await axios.get(`/receivables`, { params: { "status": 1 } });
            let data = res.data;
            setData(data);
        } catch (error) {
            enqueueSnackbar('Error fetching data', { variant: 'error' });
            setData([]);
        }
    };

    const columns = [
        {
            key: 'sno',
            label: "S No",
        },
        {
            key: 'UniqueID',
            label: "Unique ID",
            flexGrow: 1
        },
        {
            key: 'client',
            label: "Client",
            flexGrow: 1
        },
        {
            key: 'corporation',
            label: "Corporation",
            flexGrow: 2
        },
        {
            key: 'pms',
            label: "PMS",
            flexGrow: 1
        },
        {
            key: 'postedBy',
            label: "Submit by",
            flexGrow: 1
        },
    ]

    async function getTableData(receivable) {
        const { CorpID, _id } = receivable
        let res = await axios.get(`/receivables/processed_sale_info`, { params: { _id } })
        let result = res.data

        let tabWiseData = result['tables_data']
        let saleID = result['saleID']

        return { tabWiseData, saleID }
    }

    return (
        <SnackbarProvider>
            <Sales
                data={data}
                columns={columns}
                loading={!data}
                getTableData={getTableData}
                callback={fetchData}
            />
        </SnackbarProvider>
    );
};

export default ProcessedSales;
