import React, { useEffect, useState } from 'react';
import Sales from '../../Components/Sales/Sales';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import axios from 'axios';
import { Button } from '@mui/material';
import { createSale, generateToken } from '../../API/NimbleAPI';
import { GenerateFinalResponse } from '../../Tools/tools';

const Receivables = () => {
    const [data, setData] = useState();


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setData()
        try {
            let res = await axios.get(`/receivables`, { params: { "status": 0 } });
            let data = res.data;
            data.forEach(i => {
                let extraction_status = i['extraction_status']
                switch (extraction_status) {
                    case 1:
                        i['extraction_description'] = "Complete"
                        break
                    default:
                        i['extraction_description'] = "Pending"
                        break
                }
                i['bill_date_modified'] = i['bill_date'].split('T')[0]
            })
            setData(data);
        } catch (error) {
            enqueueSnackbar('Error fetching data', { variant: 'error' });
            setData([]);
        }
    };

    const columns = [
        {
            key: 'sno',
            label: "S No",
        },
        {
            key: '_id',
            label: "Unique ID",
            flexGrow: 1.5
        },
        {
            key: 'bill_date_modified',
            label: "Date",
            flexGrow: 0.8
        },
        {
            key: 'client',
            label: "Client",
            flexGrow: 1
        },
        {
            key: 'corporation',
            label: "Corporation",
            flexGrow: 2
        },
        {
            key: 'pms',
            label: "PMS",
            flexGrow: 1
        },
        {
            key: 'postedBy',
            label: "Submit by",
            flexGrow: 1
        },
        {
            key: 'extraction_description',
            label: "Extraction Status",
            flexGrow: 0
        },
    ]

    async function getTableData(receivable) {
        const { CorpID, _id } = receivable
        let res;
        let tabWiseData;

        res = await axios.get('/receivables/sale_info', { params: { _id } })
        let dataframes = res.data

        if (dataframes) {
            res = await axios.get('/receivables/property/labels', { params: { CorpID } })
            let labels = res.data

            let tempData = {
                Summary: dataframes['Summary'] || dataframes['summary'] || [],
                Detailed: dataframes['Detailed'] || dataframes['detailed'] || []
            }
            let reviewTableData = createLineItemGroups(labels, tempData, _id)

            let missingLineItems = findUnmatchedItems(labels, Object.values(tempData).flat());
            if (missingLineItems.length > 0) {
                console.table(missingLineItems)
                enqueueSnackbar(
                    'Line items are missing. Please download and review the CSV file.',
                    {
                        variant: 'error',
                        action: () =>
                            <Button color='inherit' size='small' variant='outlined' onClick={() => exportData(missingLineItems, _id)}>
                                Download
                            </Button>
                    }
                )
            }

            tabWiseData = {
                revenue: [],
                receipt: [],
                arDetails: [],
                statistics: []
            }

            Object.keys(tabWiseData).forEach(tab => {
                reviewTableData.forEach(row => {
                    if (tab.toLowerCase() === row['Dept'].toLowerCase()) {
                        tabWiseData[tab].push(row)
                    }
                })
            })

            tabWiseData['verification'] = dataframes['Verification'] || dataframes['verification'] || []
        }
        else {
            enqueueSnackbar('Cant find sale info for this receivable', { variant: 'error' })
        }
        return { tabWiseData }
    }

    return (
        <SnackbarProvider>
            <Sales
                data={data}
                columns={columns}
                loading={!data}
                getTableData={getTableData}
                callback={fetchData}
            />
        </SnackbarProvider>
    );
};

export default Receivables;


function createLineItemGroups(labels = [], data = {}, _id) {
    const currency = require('currency.js')
    const stringSimilarity = require('string-similarity');
    const uuid = require('uuid');
    labels.forEach((label, index) => {
        label['uniqueId'] = uuid.v4()
        label['lineItemsGroup_summary'] = []
        label['lineItemsGroup_Detailed'] = []
        label['netAmount'] = 0
        label['netStat'] = 0
        label['netAmount_summary'] = 0
        label['netStat_summary'] = 0

        Object.entries(data).forEach(([key, value]) => {
            let lineItemGroupKey = key?.toLowerCase() === 'summary' ? 'lineItemsGroup_summary' : 'lineItemsGroup_Detailed'
            let amountKey = key?.toLowerCase() === 'summary' ? 'netAmount_summary' : 'netAmount'
            let statKey = key?.toLowerCase() === 'summary' ? 'netStat_summary' : 'netStat'
            value.forEach(item => {
                let validate_netAmount = validateNumber(item['net_Amount'])
                let validate_stat = validateNumber(item['stat'])
                let lineItem = {
                    'lineItem': item['lineitem_desc'],
                    'amount': validate_netAmount,
                    'stat': validate_stat,
                }
                let desc = label['Label']?.toLowerCase() || ""
                let category = item?.['category']?.toLowerCase() || ""
                let similarity = stringSimilarity.compareTwoStrings(desc, category);
                
                if (label['Label']?.toLowerCase() === item?.['category']?.toLowerCase()) {
                // if (similarity >= 0.9) {
                    label[lineItemGroupKey].push(lineItem)
                    label[amountKey] += validate_netAmount
                    label[statKey] += validate_stat
                }
                label[amountKey] = currency(Number(label[amountKey]).toFixed(3)).value
                label[statKey] = Math.floor(label[statKey])
            })
        })
    })


    function validateNumber(i) {
        return i ?
            typeof (i) === 'number' ? isNaN(i) ? 0 : i
                :
                isNaN(Number(i)) ? 0 : Number(i)
            : 0
    }

    return labels
}

function findUnmatchedItems(array1, array2) {
    const labelsSet = new Set(array1.map(item => item.Label?.toLowerCase()))
    return array2.filter(item => item.lineitem_desc && /\S/.test(item.lineitem_desc) && !labelsSet.has(item.category?.toLowerCase()))
        .map(({ lineitem_desc, category, net_Amount, stat }) => ({ lineitem_desc, category, net_Amount, stat }));
}

async function exportData(data, _id) {
    let converter = require('json-2-csv');
    const { format } = require('date-fns');
    const csv = await converter.json2csv(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        let filename = `${format(new Date(), 'MMddyyyyHHmmssSSS')}-${_id}-missed-line-items.csv`;
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}