import React from 'react';
import {
    Navigate,
    Outlet,
    createBrowserRouter,
    useLocation
} from 'react-router-dom';
import Login from './Login/Login';
import Layout from '../Layout/Layout';
import Inbox from './Inbox/Inbox';
import Receivables from './Receivables/Receivables';
import ProcessedSales from './ProcessedSales/ProcessedSales';
import Labels from './Labels/Labels';

const AuthenticatedLayout = () => {
    const location = useLocation();
    const user = sessionStorage.getItem('user');

    if (!user) {
        return (
            <>
                <Navigate to={'/'} />
                <Login />
            </>
        )
    } else if (location.pathname === '/') {
        return (
            <>
                <Navigate to={'/inbox'} replace />;
                <Layout />
            </>
        );
    } else {
        return (
            <>
                <Layout />
            </>
        );
    }
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthenticatedLayout />,
        children: [
            {
                path: "inbox",
                element: <Inbox />,
            },
            {
                path: "receivables",
                element: <Receivables />
            },
            {
                path: "processed_sales",
                element: <ProcessedSales />
            },
            {
                path: "labels",
                element: <Labels />
            },
        ]
    }
]);

export default router;
