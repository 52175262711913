import React from 'react'
import { Table } from 'rsuite';
import AutoSizer from "react-virtualized-auto-sizer";

const { Column, HeaderCell, Cell } = Table;

const CustomTable = ({ columns = [], list = [], action = false, ActionComponent, loading = false }) => {

    function prepareData() {
        list.forEach((_, i) => {
            _['sno'] = i + 1
        })
        return list
    }

    return (
        <AutoSizer>
            {({ height, width }) =>
                <Table
                    cellBordered
                    bordered
                    height={height}
                    width={width}
                    virtualized
                    data={prepareData()}
                    translate3d
                    loading={loading}
                >
                    {columns.map((column, columnIndex) =>
                        <Column fixed={column['key'] === "sno" ? "left" : undefined} width={column['key'] === "sno" ? 64 : undefined} key={columnIndex} flexGrow={column['flexGrow']} fullText>
                            <HeaderCell>{column['label']}</HeaderCell>
                            <Cell dataKey={column['key']} />
                        </Column>
                    )}
                    {action &&
                        <Column fixed={'right'} align="center" verticalAlign='center'>
                            <HeaderCell>Action</HeaderCell>
                            <Cell
                                style={{ padding: 0 }}
                                children={ActionComponent} // props rowData, rowIndex
                            />
                        </Column>
                    }
                </Table>
            }
        </AutoSizer>


    )
}

export default CustomTable