// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-title {
    padding: 0px !important;
}

.ant-table-footer {
    padding: 0px !important;
}

.ant-table-thead {
    height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/Routes/Labels/Labels.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".ant-table-title {\n    padding: 0px !important;\n}\n\n.ant-table-footer {\n    padding: 0px !important;\n}\n\n.ant-table-thead {\n    height: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
