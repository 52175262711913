import React from 'react'
import {
    AppBar,
    Avatar,
    Toolbar,
    Typography
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { red } from '@mui/material/colors';
import axios from 'axios';

const Header = ({ headerContent }) => {
    const location = useLocation()
    const navigate = useNavigate()
    let user = sessionStorage.getItem('user')
    user = user ? JSON.parse(user) : {}

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let avatar = user['username']?.charAt(0)

    const logout = async () => {
        await axios.get('/logout')
        sessionStorage.clear('user')
        navigate(0)
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar sx={{ alignItems: 'center', gap: 1 }}>
                    <Typography flexGrow={1} variant="h5" textTransform={'capitalize'}>
                        {location.pathname.replace('/', "").replace("_", " ")}
                    </Typography>
                    {headerContent}

                    <Typography variant='button'>{user['username']}</Typography>

                    <IconButton
                        onClick={logout}
                        // onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 42, height: 42, color: '#46458e' }}>
                            <Logout />
                        </Avatar>
                        {/* <Avatar sx={{ width: 48, height: 48, textTransform: 'capitalize' }}>{avatar}</Avatar> */}
                    </IconButton>

                </Toolbar>
            </AppBar>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />


                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default Header