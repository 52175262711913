import React, { useState } from "react";
import { ThemeProvider } from '@emotion/react'
import { RouterProvider } from 'react-router-dom'
import router from './Routes/Routes'
import theme from './Theme/Theme'
import axios from "axios";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
