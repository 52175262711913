import React, { useState } from 'react'
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import TablePanel from './TablePanel/TablePanel';
import FilePanel from './FilePanel/FilePanel';

const SubmitPage = ({
    files,
    dataFrames,
    setDataFrames,
    setRemovedDataFrames,
    setRemovedLineItemTrack
}) => {

    const [sizes, setSizes] = useState([55, 45])
    const [start, setStart] = useState(false)

    return (
        <ReactSplit
            direction={SplitDirection.Horizontal}
            initialSizes={sizes}
            onResizeStarted={() => setStart(true)}
            onResizeFinished={(pairIdx, newSizes) => { setSizes(newSizes); setStart(false) }}
        >
            <FilePanel files={files} updateDFs={setDataFrames} start={start} />
            <TablePanel
                tables={dataFrames}
                updateTables={setDataFrames}
                updateRemovesDF={setRemovedDataFrames}
                updateRemovedLineItem={setRemovedLineItemTrack}
            />
        </ReactSplit>
    )
}

export default SubmitPage