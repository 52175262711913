// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-wrapper {
    position: fixed;
    height: 100%;
    left: 1;

    overflow-y: auto;
    width: var(--sidebar-width);
    transition: width 0.4s;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    z-index: 1200;
    /*Default MUI Drawer z-index*/
}

.sidebar-wrapper::-webkit-scrollbar {
    display: none;
}

.sidebar-list-wrapper a {
    text-decoration: none !important;
    color: inherit !important;
}

.sidebar-list-wrapper a:hover {
    text-decoration: none !important;
    color: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/Layout/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,OAAO;;IAEP,gBAAgB;IAChB,2BAA2B;IAC3B,sBAAsB;IACtB,iDAAiD;IACjD,yBAAyB;IACzB,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B","sourcesContent":[".sidebar-wrapper {\n    position: fixed;\n    height: 100%;\n    left: 1;\n\n    overflow-y: auto;\n    width: var(--sidebar-width);\n    transition: width 0.4s;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n    background-color: #ffffff;\n    z-index: 1200;\n    /*Default MUI Drawer z-index*/\n}\n\n.sidebar-wrapper::-webkit-scrollbar {\n    display: none;\n}\n\n.sidebar-list-wrapper a {\n    text-decoration: none !important;\n    color: inherit !important;\n}\n\n.sidebar-list-wrapper a:hover {\n    text-decoration: none !important;\n    color: inherit !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
