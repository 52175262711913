import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import './File.css'
import { enqueueSnackbar } from 'notistack';

export const File = ({ s3_key = "", name = "", height = '500px' }) => {
    const ext = name?.split('.').pop();

    const [file, setFile] = useState(null);

    const spreadSheet = ['xls', 'csv', 'XLSX'].includes(ext)
    const notPDF = ext !== 'pdf'

    const fileHandler = async () => {
        try {
            const response = await fetch(s3_key);
            if (spreadSheet) {
                const fileObj = await response.blob();
                ExcelRenderer(fileObj, (err, resp) => {
                    if (err) {
                        console.log(err);
                    } else {
                        setFile({
                            cols: resp.cols,
                            rows: resp.rows
                        });
                    }
                });
                return
            }
            const text = await response.text();
            setFile(text);
        }
        catch (err) {
            enqueueSnackbar(`${err}`, { variant: 'error' })
        }
    };

    useEffect(() => {
        if (notPDF) {
            fileHandler()
        }
    }, [s3_key])


    return (
        <>
            {ext === "pdf" &&
                <iframe width={'100%'} height={height} src={s3_key}>
                </iframe>
            }

            {ext === "txt" &&
                <Box height={height} width={'100%'} overflow={'auto'} component={'pre'} border={1} borderColor={'divider'}>
                    {file}
                </Box>
            }
            {spreadSheet &&
                file && (
                    <Box height={height} width={'100%'} overflow={'auto'}>
                        <OutTable
                            data={file.rows}
                            columns={file.cols}
                            tableClassName="table"
                            tableHeaderRowClass="heading"
                        />
                    </Box>
                )
            }

        </>
    )
}
