import { createTheme } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
    palette: {
        header: createColor('#46458e'),
        body: createColor('hsla(215, 15%, 97%, 0.5)')
    },
    components: {
        // MuiPaper: {
        //     styleOverrides: {
        //         root: {
        //             boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        //         },
        //     },
        // }
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#46458e'
                }
            }
        },
        typography: {
            fontFamily: [
                'Nunito',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
});

export default theme