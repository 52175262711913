import React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { Button, Skeleton, Typography } from '@mui/material';
import 'react-virtualized/styles.css';
import { format } from 'date-fns';

const VirtualizedTable = ({ datesInRange, processedData, getallattachments, loading }) => {

    return (
        <AutoSizer>
            {({ height, width }) => (
                <Table
                    width={width}
                    height={height}
                    headerHeight={0}
                    rowHeight={78}
                    rowCount={processedData.length}
                    rowGetter={({ index }) => processedData[index]}
                    rowStyle={(info) => ({ borderBottom: ![processedData.length - 1].includes(info['index']) ? '0.5px solid lightgrey' : "none" })}
                >
                    <Column
                        width={28}
                        cellRenderer={({ rowIndex }) => <Typography variant={'caption'}>{rowIndex + 1}</Typography>}
                    />
                    <Column
                        label="Name"
                        dataKey="name"
                        width={200}
                        flexGrow={7}
                        cellRenderer={({ rowData }) => <Typography>{rowData['name']}</Typography>}
                    />
                    {datesInRange.map((date, index) => (
                        <Column
                            key={index}
                            label={format(date, 'MMM d')}
                            dataKey={`emailData_${date}`}
                            width={78}
                            flexGrow={1}
                            cellRenderer={({ rowData }) => {
                                let emailInfo = rowData[date] || {};
                                return !loading ?
                                    <Button
                                        size='small'
                                        color={emailInfo.color}
                                        disabled={emailInfo.disabled}
                                        variant={emailInfo.variant}
                                        onClick={() => getallattachments(emailInfo.mail)}
                                        sx={{
                                            // pointerEvents: emailInfo.disabled ? "none" : "auto",
                                            // cursor: emailInfo.disabled ? "default" : "pointer"
                                            "&.Mui-disabled": emailInfo['mail'] && {
                                                bgcolor: (theme) => theme.palette[emailInfo.color].main,
                                                color: 'white',
                                                opacity: 0.6
                                            }
                                        }}
                                    >
                                        {format(date, 'd')}
                                        <br />
                                        {format(date, 'EEE')}
                                    </Button>
                                    :
                                    <Skeleton variant="rectangular" width={58} height={58} />
                            }}
                        />
                    ))}
                </Table>
            )}
        </AutoSizer>
    );
};

export default VirtualizedTable;
