import { Backdrop, CircularProgress } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React from 'react'

const CustomBackdrop = ({ open = false }) => {
    return (
        <Backdrop open={open} sx={{ zIndex: zIndex.drawer + 1 }}>
            <CircularProgress color='header' />
        </Backdrop>
    )
}

export default CustomBackdrop