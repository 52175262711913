// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* font-family: 'Poppins', sans-serif;  */
.inputRounded .MuiOutlinedInput-root {
    border-radius: 50px;
}

.login-form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.copyright {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Routes/Login/Login.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;AACZ","sourcesContent":["/* font-family: 'Poppins', sans-serif;  */\n.inputRounded .MuiOutlinedInput-root {\n    border-radius: 50px;\n}\n\n.login-form-wrapper {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.copyright {\n    position: absolute;\n    bottom: 30px;\n    left: 0;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
