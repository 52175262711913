import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Typography } from '@mui/material'
import { Inbox, LocalOffer, PriceCheck, Receipt } from '@mui/icons-material';
import "./NavBar.css"
import { NavLink, useLocation } from 'react-router-dom';
import logo from './logo.png'

const NavBar = () => {
    const location = useLocation();
    const [tabIndex, setTabindex] = useState(0)

    const routes = [
        {
            path: "/inbox",
            label: "Inbox",
            Icon: (props) => <Inbox {...props} />
        },
        {
            path: "/receivables",
            label: "Receivables",
            Icon: (props) => <Receipt {...props} />
        },
        {
            path: "/processed_sales",
            label: "Processed Sales",
            Icon: (props) => <PriceCheck {...props} />
        },
        {
            path: "/labels",
            label: "Labels",
            Icon: (props) => <LocalOffer {...props} />
        },
    ]

    useEffect(() => {
        let index = routes.findIndex(i => i['path'] === location.pathname)
        if (index !== -1) {
            setTabindex(index)
        }
    }, [])

    const __LOGO_SIZE = "32px"

    return (
        <List
            subheader={
                <ListSubheader disableGutters >
                    <ListItem divider sx={{ height: 64 }}>
                        <ListItemIcon>
                            <img width={__LOGO_SIZE} height={__LOGO_SIZE} className="logo" src={logo} alt="bg" />
                        </ListItemIcon>
                        <Typography variant='h5' fontWeight={'bold'} color={'#46458e'} noWrap>{"Daily Sales"}</Typography>
                    </ListItem>
                </ListSubheader>
            }
            className='sidebar-list-wrapper'
        >
            {routes.map((route, routeIndex) => {
                const Icon = route['Icon']
                return (
                    <ListItemButton
                        key={routeIndex}
                        selected={tabIndex === routeIndex}
                        onClick={() => setTabindex(routeIndex)}
                        LinkComponent={NavLink}
                        to={route['path']}
                        sx={{
                            height: 56,
                            "&.Mui-selected": {
                                bgcolor: 'rgba(70, 69, 142, 0.3)',
                                ":hover": { bgcolor: 'rgba(70, 69, 142, 0.3)' }
                            },
                            ":hover": { bgcolor: 'rgba(70, 69, 142, 0.1)' }
                        }}
                    >
                        <ListItemIcon>
                            <Icon color={'header'} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={route['label']} />
                    </ListItemButton>
                )
            })}
        </List>
    )
}

export default NavBar