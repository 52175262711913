import axios from "axios";

// const NIMBLE_AUTH_API =
//     process.env.NODE_ENV === "production"
//         ? process.env.REACT_APP_NIMBLE_AUTH_API_LIVE
//         : process.env.REACT_APP_NIMBLE_AUTH_API_STAGING;

// const NIMBLE_API =
//     process.env.NODE_ENV === "production"
//         ? process.env.REACT_APP_NIMBLE_API_LIVE
//         : process.env.REACT_APP_NIMBLE_API_STAGING;

const NIMBLE_AUTH_API = process.env.REACT_APP_NIMBLE_AUTH_API_STAGING;
const NIMBLE_API =process.env.REACT_APP_NIMBLE_API_STAGING;

const auth = axios.create({
    baseURL: `${NIMBLE_AUTH_API}/v1`,
});

const nimble = axios.create({
    baseURL: `${NIMBLE_API}/v1/DailySale`,
});

console.log(NIMBLE_AUTH_API)
console.log(NIMBLE_API)

const headers = (token) => ({ 'Authorization': `Bearer ${token}` })

export async function generateToken(clientID, clientSecret, snackbar) {
    try {
        let body = {
            "clientID": clientID,
            "clientSecret": clientSecret
        };

        const response = await auth.post('/OpenApiauth/token', body);

        return response.data.token;
    } catch (error) {
        snackbar(`Error generating token: ${error}`, { variant: 'error' })
        console.error('Error generating token:', error);
    }
}

export async function getLines(body, token, snackbar) {
    try {
        const response = await nimble.post('/Lines', body, { headers: headers(token) });
        return response.data;
    } catch (error) {
        snackbar(`Error while getting Lines from Nimble ${error}`, { variant: 'error' })
        console.error(error)
    }
}

export async function createSale(body, token, snackbar) {
    try {
        const response = await nimble.post('/Create', body, { headers: headers(token) });
        return response.data;
    } catch (error) {
        snackbar(`Error while getting Lines from Nimble ${error}`, { variant: 'error' })
        console.error(error)
    }
}
export async function updateSale(body, token, snackbar) {
    try {
        const response = await nimble.put('/Update', body, { headers: headers(token) });
        return response.data;
    } catch (error) {
        snackbar(`Error while getting Lines from Nimble ${error}`, { variant: 'error' })
        console.error(error)
    }
}
export async function deleteSale(body, token, snackbar) {
    try {
        const response = await nimble.post('/Delete', body, { headers: headers(token) });
        return response.data;
    } catch (error) {
        snackbar(`Error while getting Lines from Nimble ${error}`, { variant: 'error' })
        console.error(error)
    }
}