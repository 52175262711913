// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --sidebar-width: 64px;
    --header-color: #46458e;
}

.sidebar-wrapper:hover {
    width: calc(var(--sidebar-width) * 4);
}

.main-body-wrapper {
    margin-left: var(--sidebar-width);
}`, "",{"version":3,"sources":["webpack://./src/Layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[":root {\n    --sidebar-width: 64px;\n    --header-color: #46458e;\n}\n\n.sidebar-wrapper:hover {\n    width: calc(var(--sidebar-width) * 4);\n}\n\n.main-body-wrapper {\n    margin-left: var(--sidebar-width);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
