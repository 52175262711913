import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Select, Space, Table, Typography } from 'antd';
import './Labels.css'
import { Toolbar, Box } from '@mui/material';
import { AutoSizer } from 'react-virtualized';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { red } from '@mui/material/colors';

const Labels = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [deletedLabels, setDeletedLabels] = useState([])
    const [editingRowId, setEditingRowId] = useState(null)
    const [isAdding, setIsAdding] = useState(false)
    const tableRef = useRef()

    const isEditing = (record) => Boolean(record['_id'] === editingRowId)
    const uniqueDepts = Array.from(new Set(data?.map(i => i['Dept'])))
    const uniqueDepartments = Array.from(new Set(data?.map(i => i['Department'])))
    const allLabels = data?.map(i => ({ text: i['Label'], value: i['Label'], })) || []

    const disabled_edit = Boolean(editingRowId) || deletedLabels.length > 0 || isAdding
    const disabled_add = isAdding || deletedLabels.length > 0 || editingRowId
    const disabled_delete = isAdding || editingRowId || deletedLabels.length === 0

    useEffect(() => {
        getLabels()
    }, [])

    useEffect(() => {
        if (tableRef.current) {
            if (isAdding) {
                tableRef.current.scrollTo({ key: editingRowId })
            }
        }
    }, [isAdding])

    async function getLabels() {
        setLoading(true)
        let res = await axios.get('/labels')
        let data = res.data
        setData(data)
        setLoading(false)
        return
    }

    function edit(record) {
        setEditingRowId(record['_id'])
        form.setFieldsValue(record);
    }

    function add() {
        const uuid = require('uuid')
        setIsAdding(true)
        let newRecord = {
            "_id": uuid.v4(),
            "Label": "",
            "Department": "",
            "Dept": ""
        }
        setData(prev => [...prev, newRecord])
        edit(newRecord)
    }

    async function save() {
        try {
            let updatedLabel = await form.validateFields();
            await axios.post('/labels/update', updatedLabel, { params: { "_id": editingRowId } })
            getLabels()
            cancel()
        }
        catch (err) {
            console.log(err)
        }
    }

    async function save_new_label() {
        try {
            let newLabel = await form.validateFields();
            delete newLabel['_id']
            await axios.post('/labels/add_new', newLabel)
            await getLabels()
            tableRef.current.scrollTo({ index: data.length - 1 })
            cancel()
        }
        catch (err) {
            console.log(err)
        }
    }

    function cancel() {
        setEditingRowId(null)
        if (isAdding) {
            setIsAdding(false)
        }
    }

    function cancel_adding() {
        const updatedData = data.filter(item => item._id !== editingRowId);
        setData(updatedData);
        cancel()
    }

    async function delete_labels() {
        await axios.post('/labels/delete', deletedLabels)
        enqueueSnackbar('Deleted Successfully')
        setDeletedLabels([])
        getLabels()
    }

    return (
        <SnackbarProvider>
            <Form form={form} component={false}>
                <AutoSizer>
                    {({ width, height }) =>
                        <Box height={height} width={width} overflow={'hidden'}>
                            <Table
                                size='small'
                                scroll={{ y: height - (48 * 2), }}
                                virtual
                                bordered
                                dataSource={data}
                                ref={tableRef}
                                showSorterTooltip={false}
                                components={{
                                    body: {
                                        cell: ({
                                            children,
                                            record,
                                            dataIndex,
                                            Field,
                                            options,
                                            ...rest
                                        }) => {
                                            let editing = record ? isEditing(record) : false
                                            return (
                                                <span {...rest}>
                                                    {editing ?
                                                        <Form.Item
                                                            name={dataIndex}
                                                            style={{ margin: 0 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Please Input ${dataIndex}!`,
                                                                },
                                                            ]}
                                                        >
                                                            <Field options={options} />
                                                        </Form.Item>
                                                        :
                                                        children
                                                    }
                                                </span>
                                            )
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        title: 'S.NO',
                                        width: 64,
                                        render: (_, __, index) => index + 1
                                    },
                                    {
                                        title: 'Label',
                                        dataIndex: 'Label',

                                        filterMode: 'menu',
                                        filterSearch: true,
                                        filters: allLabels,
                                        onFilter: (value, record) => record.Label.indexOf(value) === 0,
                                        sorter: (a, b) => a.Label.localeCompare(b.Label),
                                        onCell: (record) => ({
                                            record: record,
                                            dataIndex: 'Label',
                                            Field: Input,
                                        })
                                    },
                                    {
                                        title: 'Dept',
                                        dataIndex: 'Dept',

                                        filterMode: 'menu',
                                        filterSearch: true,
                                        filters: uniqueDepts.map(i => ({ text: i, value: i })),
                                        onFilter: (value, record) => record.Dept.indexOf(value) === 0,
                                        sorter: (a, b) => a.Dept.localeCompare(b.Dept),
                                        onCell: (record) => ({
                                            record: record,
                                            dataIndex: 'Dept',
                                            Field: Select,
                                            options: uniqueDepts.map(i => ({ text: i, value: i })),
                                        })
                                    },
                                    {
                                        title: 'Department',
                                        dataIndex: 'Department',

                                        filterMode: 'menu',
                                        filterSearch: true,
                                        filters: uniqueDepartments.map(i => ({ text: i, value: i })),
                                        onFilter: (value, record) => record.Department.indexOf(value) === 0,
                                        sorter: (a, b) => a.Department.localeCompare(b.Department),
                                        onCell: (record) => ({
                                            record: record,
                                            dataIndex: 'Department',
                                            Field: Select,
                                            options: uniqueDepartments.map(i => ({ text: i, value: i })),
                                        })
                                    },
                                    {
                                        title: "Action",
                                        width: 190,
                                        align: 'center',
                                        render: (record) => isEditing(record) ?
                                            isAdding ?
                                                <Space>
                                                    <Popconfirm title="Are you sure ?" onConfirm={save_new_label} onCancel={cancel_adding}>
                                                        <Typography.Link>Add</Typography.Link>
                                                    </Popconfirm>
                                                    <Typography.Link style={{ color: red[500] }} onClick={cancel_adding} >Cancel</Typography.Link>
                                                </Space>
                                                :
                                                <Space>
                                                    <Popconfirm title="Are you sure ?" onConfirm={save} onCancel={cancel}>
                                                        <Typography.Link>Save</Typography.Link>
                                                    </Popconfirm>
                                                    <Typography.Link style={{ color: red[500] }} onClick={cancel} >Cancel</Typography.Link>
                                                </Space>
                                            :
                                            <Typography.Link disabled={disabled_edit} onClick={() => edit(record)}>Edit</Typography.Link>
                                    }
                                ]}
                                rowKey={"_id"}
                                pagination={false}
                                loading={loading}
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: (_, selectedRows) => {
                                        setDeletedLabels(selectedRows)
                                    },
                                    columnWidth: 48,
                                }}

                                footer={() =>
                                    <Toolbar variant='dense' sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                                        <Button type='primary' onClick={add} disabled={disabled_add}>
                                            Add Label
                                        </Button>
                                        <Popconfirm
                                            title="Are you sure you want to delete this selected labels?"
                                            onConfirm={delete_labels}
                                            onCancel={() => setDeletedLabels([])}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger disabled={disabled_delete}>Delete</Button>
                                        </Popconfirm>
                                    </Toolbar>
                                }
                            />
                        </Box>
                    }
                </AutoSizer>
            </Form>
        </SnackbarProvider >
    );
};
export default Labels;